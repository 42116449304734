import React from 'react'
import Link from '../components/Link'

import Layout from '../layout/Layout'
import PaddedBox from '../components/PaddedBox'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PaddedBox>
      <h1>NOT FOUND</h1>
      <p>Sorry! We couldn't find that page!</p>
      <Link to="/">Home</Link>
    </PaddedBox>
  </Layout>
)

export default NotFoundPage
